import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"fontFamily\"}");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"interFontFamily\"}");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-jakarta\"}],\"variableName\":\"jakartaFontFamily\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/AOS/AOS.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/DatadogRumInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/context/client-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/context/ServerIntlProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/context/SessionHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteSettingsProvider"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/context/SiteSettingsContext.tsx");
